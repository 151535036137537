
<template>
  <span class="attributes" v-if="edition">
    <span v-for="(value, key) in edition.attributes" :key="key">
      <span v-if="isArray(value)">
        <span v-for="(v, index) in value" class="tag pl-2 pr-2" v-if="v !== 'high res'" :key="index">#{{v}}</span>
      </span>
    </span>

  </span>
</template>

<script>
  export default {
    name: 'metadataAttributes',
    props: ['edition'],
    data() {
      return {
        isActive: false
      };
    },
    methods: {
      isString: function (value) {
        return value instanceof String;
      },
      isArray: function (value) {
        return value instanceof Array;
      },
      isBoolean: function (value) {
        return value instanceof Boolean;
      },
      isNumber: function (value) {
        return value instanceof Number;
      },
    }
  };
</script>

<style scoped lang="scss">
  button {
    font-size: 0.7rem;
    padding: 0;
  }
</style>
