<template>
  <span class="high-res-container pr-2" v-if="highResAvailable">
    <span class="" v-b-tooltip.hover :id="'tooltipButton-highRes-info-' + uuid">
      High resolution
    </span>

    <b-tooltip
      ref="tooltip"
      :target="'tooltipButton-highRes-info-' + uuid">
      Includes high resolution download
    </b-tooltip>
  </span>
</template>

<script>
  import {mapGetters, mapState} from 'vuex';
  import {BTooltip, VBTooltip} from 'bootstrap-vue';

  export default {
    components: {
      BTooltip
    },
    name: 'highResLabel',
    props: ['highResAvailable'],
    directives: {'b-tooltip': VBTooltip},
    data() {
      return {
        // We gen a random ID to bind the popup to
        uuid: Math.floor(Math.random() * Math.floor(1000))
      };
    },
    computed: {
      ...mapState([
        'account',
      ]),
    }
  };
</script>

<style scoped lang="scss">

</style>
