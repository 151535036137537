import { render, staticRenderFns } from "./HighResLabel.vue?vue&type=template&id=ad108e94&scoped=true&"
import script from "./HighResLabel.vue?vue&type=script&lang=js&"
export * from "./HighResLabel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad108e94",
  null
  
)

export default component.exports