<template>
  <div class="container">
    <loading-section :page="PAGES.LEGACY_ASSET"></loading-section>

    <div v-if="edition" class="row justify-content-sm-center">
      <div class="col col-sm-6">
        <asset :edition="edition" :key="edition.tokenId"></asset>
      </div>
    </div>

    <div v-if="!isLoading(PAGES.LEGACY_ASSET) && !edition"
         class="row justify-content-sm-center">
      <div class="col col-sm-6 text-center">
        <div class="alert alert-secondary" role="alert">
          Sorry, we cant seem to find that asset
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {mapGetters, mapState} from 'vuex';
  import Asset from '../components/v1/Asset.vue';
  import {PAGES} from '../store/loadingPageState';
  import LoadingSection from '../components/generic/LoadingSection';
  import * as actions from '../store/actions';

  export default {
    name: 'legacyDeepLinkToken',
    components: {
      LoadingSection,
      Asset
    },
    data() {
      return {
        PAGES: PAGES,
        edition: null
      };
    },
    methods: {},
    computed: {
      ...mapState([
        'editionLookupService'
      ]),
      ...mapGetters('loading', [
        'isLoading'
      ]),
    },
    created() {
      this.$store.dispatch(`loading/${actions.LOADING_STARTED}`, PAGES.LEGACY_ASSET);

      const loadData = () => {
        this.editionLookupService.loadKodaV1Token(this.$route.params.legacyTokenId)
          .then((edition) => {
            this.edition = edition;
          })
          .finally(() => {
            this.$store.dispatch(`loading/${actions.LOADING_FINISHED}`, PAGES.LEGACY_ASSET);
          });
      };

      this.$store.watch(
        () => this.editionLookupService.currentNetworkId,
        () => loadData()
      );

      if (this.editionLookupService.currentNetworkId) {
        loadData();
      }
    },
  };
</script>

<style scoped>

</style>
