<template>
  <div class="card" v-if="edition">

    <edition-image class="card-img-top" :edition="edition" :id="edition.tokenId" :full="true"/>

    <ul class="list-group list-group-flush">
      <li class="list-group-item text-center no-top-border">
        <span class="edition-data float-left">
          <token-id :value="edition.tokenId"></token-id>
          <span class="text-muted small">V1</span>
        </span>
      </li>
    </ul>

    <div class="card-body">
      <p class="card-title">{{ edition.artworkName }}</p>
      <div class="pl-1 artist-name">{{ edition.artist }}</div>
      <div class="pl-1 artist-address text-muted">{{ edition.artistAccount }}</div>

      <p class="card-full-desc pt-4">
        {{ edition.description }}
      </p>

      <high-res-label :high-res-available="edition.highResAvailable"></high-res-label>

      <metadata-attributes :attributes="edition"></metadata-attributes>

      <span class="clearfix"></span>
    </div>

    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <small>
          <span class="text-muted">Owner:</span>
          <clickable-address :eth-address="edition.owner"></clickable-address>
        </small>
      </li>
    </ul>

    <div class="card-footer text-right no-top-border">
      <price-in-eth :value="edition.priceInEther"></price-in-eth>
    </div>
  </div>
</template>

<script>
  import {mapGetters,} from 'vuex';
  import PriceInEth from '../generic/PriceInEth';
  import TokenId from '../generic/TokenId';
  import ClickableAddress from '../generic/ClickableAddress';
  import MetadataAttributes from './MetadataAttributes';
  import HighResLabel from '../highres/HighResLabel';
  import EditionImage from '../generic/EditionImage';

  export default {
    components: {
      EditionImage,
      HighResLabel,
      MetadataAttributes,
      PriceInEth,
      TokenId,
      ClickableAddress,
    },
    name: 'asset',
    props: {
      edition: {
        type: Object
      },
    },
    computed: {
      ...mapGetters([
        'findArtist'
      ])
    }
  };
</script>

<style scoped lang="scss">
  li.no-bottom-border {
    border-bottom: 0 none;
  }

  .no-top-border {
    border-top: 0 none;
  }

  .card-title {
    font-size: 1.5rem;
  }

  .card-full-desc {
    font-size: 1rem;
  }
</style>
